<template>
  <div class="terminal-detail-container d-f f-d a-c">
    <nav class="d-f j-c a-c">
      <div class="top d-f j-b a-c">
        <div class="left d-f a-c">
          <img :src="tenantLogo" width="32px" v-if="tenantLogo" />
          <img src="@/assets/images/layout_logo.png" width="32px" v-else />
          <span class="title">智慧校园管理平台</span>
          <span class="text">视频巡课详情</span>
        </div>
        <div class="right">
          <div @click="back" class="back d-f a-c">
            <img src="@/assets/images/terminal_detail_back.png" width="24px" />
            <span>返回</span>
          </div>
        </div>
      </div>
    </nav>
    <div class="content">
      <div class="middle d-f j-b">
        <div class="left d-f f-d a-c">
          <div class="selector d-f j-b">
            <div class="class">{{ deviceName }}</div>
            <div class="setting d-f a-c">
              <!-- 视频流选择 -->
              <a-form-model ref="queryForm" :model="queryParams" layout="inline">
                <a-form-model-item label="视频流" prop="channel">
                  <a-select
                    v-model="queryParams.channel"
                    placeholder="通道选择"
                    style="width: 140px"
                    :getPopupContainer="(triggerNode) => triggerNode.parentNode"
                    @change="changeChannel"
                  >
                    <a-select-option value="SCREEN">黑板</a-select-option>
                    <a-select-option value="CAMERA">摄像头</a-select-option>
                  </a-select>
                </a-form-model-item>
              </a-form-model>
            </div>
          </div>
          <div
            id="remote-video"
            style="border-radius: 8px; width: 707px; height: 398px"
          ></div>
          <div
            style="
              width: 707px;
              height: 398px;
              position: absolute;
              background: #696969;
              top: 76px;
            "
            class="d-f f-d a-c j-c"
            v-if="occupied"
          >
            <img src="@/assets/images/terminal_occupied.png" width="300" height="225" />
            <h4
              style="
                font-size: 14px;
                font-family: PingFang SC-Regular, PingFang SC;
                font-weight: 400;
                color: rgba(255, 255, 255, 0.8);
                margin-top: 24px;
              "
            >
              抱歉该设备正在进行音视频互动，无法巡课
            </h4>
          </div>
        </div>
        <div class="right">
          <div class="info">
            <div class="title">Windows硬件信息</div>
            <div class="d-f j-b a-c">
              <div class="item d-f f-d a-c">
                <div
                  class="data"
                  :class="cpuUsed < 70 ? 'normal' : cpuUsed < 90 ? 'warning' : 'danger'"
                >
                  <span>{{ cpuUsed | toFixed }}</span
                  >%
                </div>
                CPU占用
              </div>
              <div class="item d-f f-d a-c">
                <div
                  class="data"
                  :class="cpuTemp < 70 ? 'normal' : cpuTemp < 90 ? 'warning' : 'danger'"
                >
                  <span>{{ cpuTemp | toFixed }}</span
                  >℃
                </div>
                CPU温度
              </div>
              <div class="item d-f f-d a-c">
                <div
                  class="data"
                  :class="
                    memoryUsed < 0.7 ? 'normal' : memoryUsed < 0.9 ? 'warning' : 'danger'
                  "
                >
                  <span>{{ memoryUsed | transformPercent }}</span
                  >%
                </div>
                内存占用
              </div>
              <div class="item d-f f-d a-c">
                <div
                  class="data"
                  :class="
                    diskUsed < 0.7 ? 'normal' : diskUsed < 0.9 ? 'warning' : 'danger'
                  "
                >
                  <span>{{ diskUsed | transformPercent }}</span
                  >%
                </div>
                硬盘使用
              </div>
            </div>
          </div>
          <div class="operation">
            <div class="title">黑板指令操作</div>
            <div class="d-f f-w">
              <button class="btn off d-f j-c a-c" @click="turnOff">
                <img src="@/assets/images/terminal_off.png" width="20" />
                <div style="height: 20px">关机</div>
              </button>
              <button class="btn reon d-f j-c a-c" @click="restart">
                <img src="@/assets/images/terminal_reon.png" width="20" />
                <div style="height: 20px">重启</div>
              </button>
              <button class="btn lock" @click="restOrBrightScreen">休眠/唤醒</button>
              <button class="btn other" @click="lockOrUnlock">锁定/解锁</button>
              <button class="btn other" @click="usb">USB禁用/启用</button>
              <button class="btn other" @click="newsSend">消息推送</button>
              <button class="btn other" @click="fileSend">文件下发</button>
              <button class="btn other" @click="restoreSetting">恢复出厂设置</button>
              <button class="btn other" @click="restorePc">ops还原</button>
              <!-- <button class="btn other">其他操作</button> -->
            </div>
          </div>
        </div>
      </div>
      <div class="bottom">
        <div class="left">
          <a-menu v-model="current" mode="horizontal">
            <a-menu-item key="Android">安卓系统信息</a-menu-item>
            <a-menu-item key="windows">Windows基本信息</a-menu-item>
          </a-menu>
          <div class="info-list" v-if="current == 'Android'">
            <div class="item">
              <span>运行内存：</span>
              {{ handleSize(androidDetail.memorySize, "ceil") }}
            </div>
            <div class="item">
              <span>存储空间：</span>
              可用空间:
              {{ handleSize(androidDetail.usedDiskSize, "floor") }}，总容量:{{
                handleSize(androidDetail.totalDiskSize, "ceil")
              }}
            </div>
            <div class="item">
              <span>MAC地址：</span>
              {{ androidDetail.macAddress }}
            </div>
            <div class="item">
              <span>设备编号：</span>
              {{ androidDetail.serialNum }}
            </div>
            <div class="item">
              <span>安卓版本：</span>
              {{ androidDetail.androidVersion }}
            </div>
          </div>
          <div class="info-list" v-else>
            <div class="item">
              <span>运行内存：</span>
              {{ handleSize(windowsDetail.memorySize, "floor") }}
            </div>
            <div class="item">
              <span>存储空间：</span>
              可用空间:
              {{ handleSize(windowsDetail.usedDiskSize, "floor") }}，总容量:{{
                handleSize(windowsDetail.totalDiskSize, "floor")
              }}
            </div>
            <div class="item">
              <span>CPU信息：</span>
              {{ windowsDetail.cpuInfo }}
            </div>
            <div class="item">
              <span>唯一标识：</span>
              {{ windowsDetail.serialNum }}
            </div>
            <div class="item">
              <span>版本信息：</span>
              {{ windowsDetail.versionInfo }}
            </div>
          </div>
        </div>
        <div class="box" @click="wait">
          <div class="status d-f j-b a-c">
            ON
            <a-switch disabled v-model="status" />
          </div>
          <div class="d-f j-b">
            <div class="device">
              <img src="@/assets/images/terminal_platform.png" width="40" />
              <p>讲台</p>
            </div>
            <div class="command d-f f-d">
              <button>重启</button>
              <button class="mt10">关机</button>
            </div>
          </div>
        </div>
        <div class="box" @click="wait">
          <div class="status d-f j-b a-c">
            ON
            <a-switch disabled v-model="status" />
          </div>
          <div class="d-f j-b">
            <div class="device">
              <img src="@/assets/images/terminal_air_conditioner.png" width="40" />
              <p>空调</p>
            </div>
            <div class="command d-f f-d">
              <button>开</button>
              <button class="mt10">关</button>
            </div>
          </div>
        </div>
        <div class="box" @click="wait">
          <div class="status d-f j-b a-c">
            ON
            <a-switch disabled v-model="status" />
          </div>
          <div class="d-f j-b">
            <div class="device">
              <img src="@/assets/images/terminal_light.png" width="40" />
              <p>教室灯</p>
            </div>
            <div class="command d-f f-d">
              <button>开</button>
              <button class="mt10">关</button>
            </div>
          </div>
        </div>
        <div class="box" @click="wait">
          <div class="status d-f j-b a-c">
            ON
            <a-switch disabled v-model="status" />
          </div>
          <div class="d-f j-b">
            <div class="device">
              <img src="@/assets/images/terminal_light.png" width="40" />
              <p>窗帘</p>
            </div>
            <div class="command d-f f-d">
              <button>开</button>
              <button class="mt10">合</button>
            </div>
          </div>
        </div>
        <div class="box" @click="wait">
          <div class="status d-f j-b a-c">
            ON
            <a-switch disabled v-model="status" />
          </div>
          <div class="d-f j-b">
            <div class="device">
              <img src="@/assets/images/terminal_recording.png" width="40" />
              <p>录播</p>
            </div>
            <div class="command d-f f-d">
              <button>重启</button>
              <button class="mt10">关机</button>
            </div>
          </div>
        </div>
        <div class="box" @click="wait">
          <div class="status d-f j-b a-c">
            ON
            <a-switch disabled v-model="status" />
          </div>
          <div class="d-f j-b">
            <div class="device">
              <img src="@/assets/images/terminal_fresh_air.png" width="40" />
              <p>新风系统</p>
            </div>
            <div class="command d-f f-d">
              <button>开</button>
              <button class="mt10">关</button>
            </div>
          </div>
          <div class="dust"></div>
        </div>
        <div class="box" @click="wait">
          <div class="status d-f j-b a-c">
            ON
            <a-switch disabled v-model="status" />
          </div>
          <div class="d-f j-b">
            <div class="device">
              <img src="@/assets/images/terminal_air_switch.png" width="40" />
              <p>智能空开</p>
            </div>
            <div class="command d-f f-d">
              <button>开</button>
              <button class="mt10">关</button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 消息推送的弹出框 -->
    <a-modal
      title="消息推送"
      v-model="newsVisible"
      @ok="newsSendOk"
      @cancel="newsSendCancel"
    >
      <a-form-model
        ref="ruleNewsForm"
        :model="newsForm"
        :rules="newsRules"
        :label-col="labelCol"
        :wrapper-col="wrapperCol"
      >
        <a-form-model-item label="展示时长" prop="showDuration">
          <a-select
            v-model="newsForm.showDuration"
            placeholder="请选择展示时长"
            :getPopupContainer="(triggerNode) => triggerNode.parentNode"
          >
            <a-select-option
              :value="item.valueData"
              v-for="item in showDurationList"
              :key="item.id"
              >{{ item.valueName }}</a-select-option
            >
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="允许关闭" prop="closeFlag">
          <a-select
            v-model="newsForm.closeFlag"
            placeholder="请选择终端是否关闭通知"
            :getPopupContainer="(triggerNode) => triggerNode.parentNode"
          >
            <a-select-option
              :value="item.valueData"
              v-for="item in closeFlagList"
              :key="item.id"
              >{{ item.valueName }}</a-select-option
            >
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="消息内容" prop="content" class="content">
          <a-input
            v-model="newsForm.content"
            type="textarea"
            placeholder="请输入消息内容"
            @change="getContentLength"
            :rows="6"
          />
          <div class="content-count">{{ contentCount }}/200</div>
        </a-form-model-item>
      </a-form-model>
    </a-modal>

    <!-- 文件下发的弹出框 -->
    <a-modal
      title="文件下发"
      v-model="fileVisible"
      @ok="fileSendOk"
      @cancel="fileSendCancel"
    >
      <a-spin :spinning="confirmLoading">
        <a-form-model
          ref="ruleFileForm"
          :model="fileForm"
          :rules="fileRules"
          :label-col="labelCol"
          :wrapper-col="wrapperCol"
        >
          <a-form-model-item label="自动打开" prop="autoOpenFlag">
            <a-select
              v-model="fileForm.autoOpenFlag"
              placeholder="请选择是否自动打开"
              :getPopupContainer="(triggerNode) => triggerNode.parentNode"
            >
              <a-select-option
                :value="item.valueData"
                v-for="item in autoOpenFlagList"
                :key="item.id"
                >{{ item.valueName }}</a-select-option
              >
            </a-select>
          </a-form-model-item>
          <a-form-model-item label="上传文件" prop="file">
            <a-upload-dragger
              :file-list="fileList"
              :remove="handleRemove"
              :before-upload="beforeUpload"
              accept=".ppt, .pptx, .xlsx, .xls, .doc, .docx, .zip, .pdf, .png, .jpg"
            >
              <p class="ant-upload-drag-icon">
                <a-icon type="inbox" />
              </p>
              <p class="ant-upload-text">点击或将文件拖到这里上传</p>
            </a-upload-dragger>
          </a-form-model-item>
        </a-form-model>
      </a-spin>
    </a-modal>
  </div>
</template>

<script>
import { getStreamToken } from "@/api/device/terminal.js";
import actionType from "@/utils/action-type";
import { websocketSend } from "@/utils/websocket-send.js";
import { v4 as uuidv4 } from "uuid";
import { getConfigByConfigKey } from "@/api/setting/config.js";
import { getStsInfo } from "@/api/cloud-disk/document.js";
import Client from "@/utils/oss.js";
import { ZegoExpressEngine } from "zego-express-engine-webrtc";
export default {
  name: "TerminalDetail",
  data() {
    return {
      //地址栏的serialNum
      receiverId: "",
      //设备名称
      deviceName: "",
      //websocket发送
      websocket: null,
      //发送心跳的定时器
      timer: null,
      //发送查询设备列表信息的定时器
      timer1: null,
      //websocket连接需要的标识
      uuid: "",
      //视频流需要的参数
      streamQuery: {},
      //流实例
      zg: null,
      //流Id地址数组
      streamIdList: [],
      //流地址
      streamID: null,
      //互动占用图片的显示隐藏
      occupied: false,
      //视频流选择
      queryParams: {
        channel: "CAMERA",
      },
      //cup占用
      cpuUsed: null,
      //cpu温度
      cpuTemp: null,
      //内存占用
      memoryUsed: null,
      //硬盘使用
      diskUsed: null,
      //menu选择当前设备
      current: ["Android"],
      //windows基本信息
      windowsDetail: {
        versionInfo: "",
        macAddress: "",
        memorySize: "",
        serialNum: "",
        totalDiskSize: "",
        usedDiskSize: "",
      },
      //安卓基本信息
      androidDetail: {
        androidVersion: "",
        cpuInfo: "",
        memorySize: "",
        serialNum: "",
        totalDiskSize: "",
        usedDiskSize: "",
      },
      //消息推送弹出框显示隐藏
      newsVisible: false,
      //展示时长列表
      showDurationList: [],
      //终端关闭通知
      closeFlagList: [],
      //消息通知内容汉字数
      contentCount: 0,
      //消息推送表单
      newsForm: {},
      //消息推送表单规则
      newsRules: {
        content: [
          {
            required: true,
            message: "请输入消息内容",
            whitespace: true,
            trigger: ["change", "blur"],
          },
          {
            min: 0,
            message: "请输入1-200之内的汉字",
            max: 200,
            trigger: ["change", "blur"],
          },
        ],
        showDuration: [
          {
            required: true,
            message: "请选择展示时长",
            whitespace: true,
            trigger: ["change", "blur"],
          },
        ],
        closeFlag: [
          {
            required: true,
            message: "请选择是否允许终端关闭",
            whitespace: true,
            trigger: ["change", "blur"],
          },
        ],
      },
      //label和wrapper的占位
      labelCol: { span: 4 },
      wrapperCol: { span: 18 },
      //文件下发显示隐藏
      fileVisible: false,
      //文件大小最大限制
      fileMaxSize: 0,
      //文件列表
      fileList: [],
      //文件下发表单
      fileForm: {},
      //文件下发表单规则
      fileRules: {
        autoOpenFlag: [
          {
            required: true,
            message: "请选择是否允许自动打开",
            whitespace: true,
            trigger: ["change", "blur"],
          },
        ],
      },
      //文件上传的loading效果
      confirmLoading: false,
      //sts密钥
      dataObj: null,
      //自动打开列表
      autoOpenFlagList: [],
      //设备状态
      status: true,
    };
  },
  computed: {
    //租户logo
    tenantLogo() {
      return this.$ls.get("userInfo").tenantLogo;
    },
  },
  filters: {
    transformPercent(val) {
      if (val) {
        return (val * 100).toFixed(0);
      } else {
        return null;
      }
    },
    toFixed(val) {
      if (val != null) {
        return val.toFixed(0);
      }
    },
  },
  methods: {
    //计算内存
    handleSize(size, type) {
      if (size != null && type == "floor") {
        let KB = Number(size) / 1024;
        let KBInt = Math.floor(KB);
        if (KBInt > 1024) {
          let MB = KB / 1024;
          let MBInt = Math.floor(MB);
          if (MBInt > 1024) {
            let GB = MB / 1024;
            return GB.toFixed(2) + "G";
          } else {
            return MB.toFixed(2) + "M";
          }
        } else {
          return KB.toFixed(2) + "KB";
        }
      } else if (size != null && type == "ceil") {
        let KB = Number(size) / 1024;
        let KBInt = Math.floor(KB);
        if (KBInt > 1024) {
          let MB = KB / 1024;
          let MBInt = Math.floor(MB);
          if (MBInt > 1024) {
            let GB = MB / 1024;
            return Math.ceil(GB) + "G";
          } else {
            return Math.ceil(MB) + "M";
          }
        } else {
          return Math.ceil(KB) + "KB";
        }
      }
    },
    //返回上一级路由
    back() {
      this.$router.go(-1);
    },
    //获取视频流token
    getStreamToken() {
      getStreamToken({
        uid: this.$ls.get("userInfo").id + "-" + this.uuid,
      }).then((res) => {
        this.streamQuery = res.data;
        //外网访问推流
        let startStreaming = websocketSend(
          this.uuid,
          actionType.startStreaming,
          {
            channel: this.queryParams.channel,
            networkType: "WAN",
          },
          [this.receiverId]
        );
        if (this.websocket == null) {
          this.$message.error("连接已断开，请刷新页面");
          this.websocketclose();
        } else {
          this.websocket.send(JSON.stringify(startStreaming));
        }
      });
    },
    //视频流通道改变
    async changeChannel() {
      //有实例有摄像头
      if (this.zg) {
        this.zg.stopPlayingStream(this.streamID);
        let roomID = this.receiverId;
        let inputMessage = this.queryParams.channel;
        try {
          await this.zg.sendBroadcastMessage(roomID, inputMessage);
        } catch (error) {
          console.log(">>> sendMsg, error: ", error);
        }
      }
      //没有实例或者实例被销毁
      else {
        //没有被互动占用 建立实例
        if (!this.occupied) {
          this.getStreamToken();
        }
      }
    },
    //开始拉流
    async startPlayingStream() {
      let roomID = this.receiverId;
      for (let i = 0; i < this.streamIdList.length; i++) {
        if (this.streamIdList[i] === roomID + "-" + this.queryParams.channel) {
          this.streamID = this.streamIdList[i];
          // streamList中有对应流的 streamID
          const remoteStream = await this.zg.startPlayingStream(this.streamID);
          // 创建媒体流播放组件对象，用于播放远端媒体流 。
          const remoteView = this.zg.createRemoteStreamView(remoteStream);
          document.getElementById("remote-video").innerHTML = "";
          // 将播放组件挂载到页面，"remote-video" 为组件容器 DOM 元素的 id 。
          remoteView.play("remote-video");
          break;
        }
      }
    },
    //webscoket 初始化
    initWebSocket() {
      const wsurl = process.env.VUE_APP_WSURL; //ws 相当于http 而wss 相当于https
      // let token = this.$ls.get("access_token");
      // this.websocket = new WebSocket(wsurl, [token]); //实例对象
      this.websocket = new WebSocket(wsurl); //实例对象
      console.log("websocket建立连接");
      this.websocket.onmessage = this.websocketonmessage;
      this.websocket.onopen = this.websocketonopen;
      this.websocket.onerror = this.websocketonerror;
      this.websocket.onclose = this.websocketclose;
    },
    //连接建立
    websocketonopen() {
      console.log("前端连接建立成功");
      this.uuid = uuidv4().replace(/-/g, "");
      let open = websocketSend(this.uuid, actionType.open);
      if (this.websocket == null) {
        this.$message.error("连接已断开，请刷新页面");
        this.websocketclose();
      } else {
        this.websocket.send(JSON.stringify(open));
      }
    },
    //发送心跳
    websocketHeart() {
      console.log("发送心跳");
      let heart = websocketSend(this.uuid, actionType.heart);
      if (this.websocket == null) {
        this.$message.error("连接已断开，请刷新页面");
        this.websocketclose();
      } else {
        this.websocket.send(JSON.stringify(heart));
        this.timer = setInterval(() => {
          if (this.websocket == null) {
            this.$message.error("连接已断开，请刷新页面");
            this.websocketclose();
          } else {
            this.websocket.send(JSON.stringify(heart));
          }
        }, 10 * 1000);
      }
    },
    //查询系统信息
    querySystemInfo() {
      console.log("请求查询系统信息");
      let receiverIds = [];
      receiverIds.push(this.receiverId);
      let querySystemInfo = websocketSend(
        this.uuid,
        actionType.querySystemInfo,
        null,
        receiverIds
      );
      if (this.websocket == null) {
        this.$message.error("连接已断开，请刷新页面");
        this.websocketclose();
      } else {
        this.websocket.send(JSON.stringify(querySystemInfo));
        let random = Math.round(Math.random() * 1000);
        this.timer1 = setInterval(() => {
          if (this.websocket == null) {
            this.$message.error("连接已断开，请刷新页面");
            this.websocketclose();
          } else {
            this.websocket.send(JSON.stringify(querySystemInfo));
          }
        }, 5 * 1000 + random);
      }
    },
    //查询设备和安卓信息
    queryDeviceAndAndroidInfo() {
      console.log("请求查询设备和安卓信息");
      let receiverIds = [];
      receiverIds.push(this.receiverId);
      let queryDeviceAndAndroidInfo = websocketSend(
        this.uuid,
        actionType.queryDeviceAndAndroidInfo,
        null,
        receiverIds
      );
      if (this.websocket == null) {
        this.$message.error("连接已断开，请刷新页面");
        this.websocketclose();
      } else {
        this.websocket.send(JSON.stringify(queryDeviceAndAndroidInfo));
      }
    },
    //数据接收
    websocketonmessage(e) {
      let data = JSON.parse(e.data);
      console.log("接收后端返回数据", data);
      //连接成功
      if (data.code === 8000) {
        this.websocketHeart();
        this.querySystemInfo();
        this.queryDeviceAndAndroidInfo();
        this.getStreamToken();
      }
      //关机指令已发送
      else if (data.code === 8005) {
        this.$message.success("设备关机中");
        setTimeout(() => {
          this.$router.go(-1);
        }, 2000);
      }
      //重启消息已发送
      else if (data.code === 8007) {
        this.$message.success("设备重启中");
        setTimeout(() => {
          this.$router.go(-1);
        }, 2000);
      }
      //消息推送后返回
      else if (data.code === 8012) {
        this.$message.success(data.msg);
        this.newsVisible = false;
        this.newsSendCancel();
      }
      //文件下发后返回
      else if (data.code === 8014) {
        this.$message.success("上传成功");
        this.confirmLoading = false;
        this.fileVisible = false;
        this.fileSendCancel();
      } else if (data.code === 8023) {
        return;
      }
      //查询系统信息成功
      else if (data.code === 8024) {
        this.cpuUsed = Number(data.data.cpuUsed);
        this.cpuTemp = Number(data.data.cpuTemp);
        this.memoryUsed = Number(data.data.memoryUsed);
        this.diskUsed = Number(data.data.diskUsed);
      }
      //查询设备和安卓信息
      else if (data.code === 8025) {
        if (data.data.windows) this.windowsDetail = data.data.windows;
        if (data.data.android) this.androidDetail = data.data.android;
      }
      //客户端收到推流信息，web开始拉流
      else if (data.code === 8027) {
        this.$message.success("正在获取视频资源");
        // 项目唯一标识 AppID，Number 类型，请从 ZEGO 控制台获取
        let appID = this.streamQuery.appId;
        // 接入服务器地址 Server，String 类型，请从 ZEGO 控制台获取
        let server = this.streamQuery.server;

        let userID = this.$ls.get("userInfo").id + "-" + this.uuid;
        let roomID = this.receiverId;
        let token = this.streamQuery.token;

        // 初始化实例
        this.zg = new ZegoExpressEngine(appID, server);

        //登录房间
        this.zg
          .loginRoom(
            roomID,
            token,
            { userID, userName: this.queryParams.channel },
            { userUpdate: true }
          )
          .then((result) => {
            if (result == true) {
              console.log("login success");
            }
          });
        //zego token过期处理
        this.zg.on("tokenWillExpire", (roomID) => {
          // 重新请求开发者服务端获取 Token
          getStreamToken({
            uid: this.$ls.get("userInfo").id + "-" + this.uuid,
          }).then((res) => {
            this.streamQuery = res.data;
            this.zg.renewToken(this.streamQuery.token);
          });
        });

        // 流状态更新回调
        this.zg.on(
          "roomStreamUpdate",
          async (roomID, updateType, streamList, extendedData) => {
            //当updateType为ADD时，代表有音视频流新增，此时可以调用startPlayingStream接口拉取播放该音视频流
            if (updateType == "ADD") {
              //流新增
              console.log("流增加");
              //this.streamIdList加入新的流
              streamList.forEach((r) => {
                this.streamIdList.push(r.streamID);
              });
              //这里为了使示例代码更加简洁，我们只拉取新增的音视频流列表中第的第一条流，在实际的业务中，建议开发者循环遍历 streamList ，拉取每一条音视频流
              this.startPlayingStream();
            }
            // 流删除，停止拉流
            else if (updateType == "DELETE") {
              // 流删除，通过流删除列表 streamList 中每个流的 streamID 进行停止拉流。
              // const streamID = streamList[0].streamID;
              streamList.forEach((element) => {
                if (element.streamID === roomID + "-" + this.queryParams.channel) {
                  this.zg.stopPlayingStream(element.streamID);
                  this.streamIdList.splice(
                    this.streamIdList.indexOf(element.streamID),
                    1
                  );
                }
              });
            }
          }
        );

        //收到广播消息的通知
        this.zg.on("IMRecvBroadcastMessage", (roomID, chatData) => {
          console.log("广播消息IMRecvBroadcastMessage", roomID, chatData);
          let message = JSON.parse(chatData[0].message);
          //切换视频流
          if (message.msgType === 0) {
            message.data.forEach(async (r) => {
              //可以拉流
              if (
                this.streamIdList.includes(roomID + "-" + this.queryParams.channel) &&
                r.channel === this.queryParams.channel &&
                r.status != 0
              ) {
                console.log("可以拉流");
                this.startPlayingStream();
              } else if (r.channel === this.queryParams.channel && r.status == 0) {
                this.$message.error(r.msg);
              }
            });
          }
          //设备在被互动占用
          else if (message.msgType === 1) {
            if (this.zg != null && this.streamID != null) {
              //停止拉流
              this.zg.stopPlayingStream(this.streamID);
              //退出房间
              this.zg.logoutRoom(roomID);
              this.streamID = null;
            }
            this.zg = null;
            this.occupied = true;
          }
        });
      } else if (data.code > 0) {
        this.$message.success(data.msg);
      }
      //文件下发后返回
      else if (data.code === -8014) {
        this.$message.error(data.msg);
        this.confirmLoading = false;
      }
      //设备离线
      else if (data.code === -8034) {
        this.$message.error("当前设备已离线");
        this.websocketclose();
        setTimeout(() => {
          this.$router.go(-1);
        }, 2000);
      } else if (data.code < 0) {
        this.$message.error(data.msg);
      }
    },
    //连接建立失败重连
    websocketonerror() {
      clearInterval(this.timer);
      clearInterval(this.timer1);
      this.timer = null;
      this.timer1 = null;
      this.websocket = null;
      this.initWebSocket();
    },
    //连接关闭
    websocketclose(e) {
      if (this.zg != null && this.streamID != null) {
        let roomID = this.receiverId;
        //停止拉流
        this.zg.stopPlayingStream(this.streamID);
        //退出房间
        this.zg.logoutRoom(roomID);
        this.streamID = null;
        this.zg = null;
      }
      console.log("websocket断开连接");
      clearInterval(this.timer);
      clearInterval(this.timer1);
      this.timer = null;
      this.timer1 = null;
      this.websocket = null;
    },
    //关机
    turnOff() {
      this.$confirm({
        title: "您确认关机吗？",
        onOk: () => {
          let receiverIds = [];
          receiverIds.push(this.receiverId);
          console.log("发送关机指令");
          let turnOff = websocketSend(this.uuid, actionType.turnOff, null, receiverIds);
          if (this.websocket == null) {
            this.$message.error("连接已断开，请刷新页面");
            this.websocketclose();
          } else {
            this.websocket.send(JSON.stringify(turnOff));
          }
        },
      });
    },
    //重启
    restart() {
      this.$confirm({
        title: "您确认重启吗？",
        onOk: () => {
          let receiverIds = [];
          receiverIds.push(this.receiverId);
          console.log("发送关机指令");
          let restart = websocketSend(this.uuid, actionType.restart, null, receiverIds);
          if (this.websocket == null) {
            this.$message.error("连接已断开，请刷新页面");
            this.websocketclose();
          } else {
            this.websocket.send(JSON.stringify(restart));
          }
        },
      });
    },
    //休眠/唤醒
    restOrBrightScreen() {
      this.$confirm({
        title: "您确认休眠/唤醒吗？",
        onOk: () => {
          let receiverIds = [];
          receiverIds.push(this.receiverId);
          console.log("发送休眠/唤醒指令");
          let restOrBrightScreen = websocketSend(
            this.uuid,
            actionType.restOrBrightScreen,
            null,
            receiverIds
          );
          if (this.websocket == null) {
            this.$message.error("连接已断开，请刷新页面");
            this.websocketclose();
          } else {
            this.websocket.send(JSON.stringify(restOrBrightScreen));
          }
        },
      });
    },
    //锁定/解锁
    lockOrUnlock(item) {
      this.$confirm({
        title: "您确认锁定/解锁吗？",
        onOk: () => {
          let receiverIds = [];
          receiverIds.push(this.receiverId);
          console.log("发送锁定/解锁指令");
          let lockOrUnlock = websocketSend(
            this.uuid,
            actionType.lockOrUnlock,
            null,
            receiverIds
          );
          if (this.websocket == null) {
            this.$message.error("连接已断开，请刷新页面");
            this.websocketclose();
          } else {
            this.websocket.send(JSON.stringify(lockOrUnlock));
          }
        },
      });
    },
    //usb禁用/启用
    usb(item) {
      this.$confirm({
        title: "您确认usb禁用/启用吗？",
        onOk: () => {
          let receiverIds = [];
          receiverIds.push(this.receiverId);
          console.log("发送usb禁用/启用指令");
          let usb = websocketSend(this.uuid, actionType.usb, null, receiverIds);
          if (this.websocket == null) {
            this.$message.error("连接已断开，请刷新页面");
            this.websocketclose();
          } else {
            this.websocket.send(JSON.stringify(usb));
          }
        },
      });
    },
    //消息推送
    async newsSend() {
      await this.getShowDurationList();
      await this.getCloseFlagList();
      this.newsVisible = true;
    },
    //获取消息展示时长列表
    getShowDurationList() {
      this.getDictDataList("device_terminal_show_duration").then((res) => {
        this.showDurationList = res.data;
      });
    },
    //获取终端关闭通知列表
    getCloseFlagList() {
      this.getDictDataList("device_terminal_close_flag").then((res) => {
        this.closeFlagList = res.data;
      });
    },
    //获取文件自动打开列表
    getAutoOpenFlagList() {
      this.getDictDataList("device_terminal_auto_open_flag").then((res) => {
        this.autoOpenFlagList = res.data;
      });
    },
    //消息通知内容改变
    getContentLength() {
      this.contentCount = this.newsForm.content.length;
    },
    //消息推送确定
    newsSendOk() {
      this.$refs.ruleNewsForm.validate((valid) => {
        if (valid) {
          this.$confirm({
            title: "您确认消息推送吗？",
            onOk: () => {
              this.newsForm.showChannel = "1";
              let newsSend = websocketSend(
                this.uuid,
                actionType.newsSend,
                this.newsForm,
                [this.receiverId]
              );
              if (this.websocket == null) {
                this.$message.error("连接已断开，请刷新页面");
                this.websocketclose();
              } else {
                this.websocket.send(JSON.stringify(newsSend));
              }
            },
          });
        }
      });
    },
    //消息推送取消
    newsSendCancel() {
      this.$refs.ruleNewsForm.resetFields();
      this.contentCount = 0;
    },
    //文件下发
    async fileSend() {
      await this.getAutoOpenFlagList();
      let res = await getConfigByConfigKey({ configKey: "fileMaxSize" });
      this.fileMaxSize = res.data;
      this.fileVisible = true;
    },
    //移除选择的文件
    handleRemove(file) {
      const index = this.fileList.indexOf(file);
      const newFileList = this.fileList.slice();
      newFileList.splice(index, 1);
      this.fileList = newFileList;
    },
    //上传后提交前的操作
    beforeUpload(file) {
      if (file.size < this.fileMaxSize) {
        this.fileList = [];
        this.fileList = [...this.fileList, file];
      } else {
        this.$message.error("文件大小超过限制");
      }
      return false;
    },
    //文件下发确定
    fileSendOk() {
      this.$refs.ruleFileForm.validate((valid) => {
        if (valid) {
          this.$confirm({
            title: "您确认文件下发吗？",
            onOk: async () => {
              if (this.fileList.length == 0) {
                this.$message.error("请上传文件");
              } else {
                this.confirmLoading = true;
                //获取Token
                let res = await getStsInfo();
                const {
                  region,
                  bucket,
                  accessKeyId,
                  accessKeySecret,
                  securityToken,
                } = res.data;
                this.dataObj = {
                  region,
                  bucket,
                  accessKeyId,
                  accessKeySecret,
                  stsToken: securityToken,
                };
                let time = new Date().getTime();
                let fileName = time + "_" + this.fileList[0].name;
                let tenantId = this.$ls.get("userInfo").tenantId;
                let schoolNum = this.$ls.get("userInfo").schoolNum;
                let userId = this.$ls.get("userInfo").id;
                let filePath = `shareFile/${tenantId}/${schoolNum}/${userId}/${fileName}`;
                Client(this.dataObj)
                  .multipartUpload(filePath, this.fileList[0], {
                    partSize: 2 * 1024 * 1024,
                    progress: (p) => {},
                  })
                  .then((result) => {
                    if (result.res.statusCode === 200) {
                      let fileSend = websocketSend(
                        this.uuid,
                        actionType.fileSend,
                        {
                          fileName,
                          filePath,
                          autoOpenFlag: this.fileForm.autoOpenFlag,
                        },
                        [this.receiverId]
                      );
                      console.log(fileSend);
                      if (this.websocket == null) {
                        this.$message.error("连接已断开，请刷新页面");
                        this.websocketclose();
                      } else {
                        this.websocket.send(JSON.stringify(fileSend));
                      }
                    }
                  })
                  .catch((error) => {
                    console.log(error);
                    this.$message.error(error);
                  });
              }
            },
          });
        }
      });
    },
    //文件下发取消
    fileSendCancel() {
      this.$refs.ruleFileForm.resetFields();
      this.fileList = [];
      this.confirmLoading();
    },
    //恢复出厂设置
    restoreSetting() {
      this.$confirm({
        title: "您确认恢复出厂设置吗？请确保设备不要断电",
        onOk: () => {
          let receiverIds = [];
          receiverIds.push(this.receiverId);
          console.log("发送恢复出厂设置指令");
          let restoreSetting = websocketSend(
            this.uuid,
            actionType.restoreSetting,
            null,
            receiverIds
          );
          if (this.websocket == null) {
            this.$message.error("连接已断开，请刷新页面");
            this.websocketclose();
          } else {
            this.websocket.send(JSON.stringify(restoreSetting));
          }
        },
      });
    },
    //还原电脑
    restorePc() {
      this.$confirm({
        title: "您确认还原电脑吗？请确保设备不要断电",
        onOk: () => {
          let receiverIds = [];
          receiverIds.push(this.receiverId);
          console.log("发送还原电脑指令");
          let restorePc = websocketSend(
            this.uuid,
            actionType.restorePc,
            null,
            receiverIds
          );
          if (this.websocket == null) {
            this.$message.error("连接已断开，请刷新页面");
            this.websocketclose();
          } else {
            this.websocket.send(JSON.stringify(restorePc));
          }
        },
      });
    },
    //正在开发中
    wait() {
      this.$info({
        title: "开发中...",
        // okText: "确认",
        // onOk: () => {
        // },
      });
    },
  },
  created() {
    this.receiverId = this.$route.query.serialNum;
    this.deviceName = this.$route.query.deviceName;
    this.initWebSocket();
  },
  destroyed() {
    console.log("页面销毁");
    this.websocketclose();
  },
};
</script>

<style scoped lang="less">
.normal {
  color: #009fe8;
}
.warning {
  color: #ff9d4d;
}

.danger {
  color: #f5222d;
}
.terminal-detail-container {
  width: 100%;
  min-height: 100vh;
  background: rgba(240, 242, 245, 0.39);
  opacity: 1;

  nav {
    width: 100%;
    height: 52px;
    background: rgba(255, 255, 255, 0.39);
    box-shadow: 0px 3px 6px rgba(0, 21, 41, 0.12);
    opacity: 1;

    .top {
      width: 1184px;

      .left {
        .title {
          margin-left: 8px;
          font-size: 14px;
          font-family: "PingFang SC";
          font-weight: bold;
          color: rgba(0, 0, 0, 0.85);
        }

        .text {
          margin-left: 24px;
          font-size: 14px;
          font-family: "PingFang SC";
          font-weight: 400;
          color: rgba(0, 0, 0, 0.65);
        }
      }

      .right {
        .back {
          margin-left: 42px;
          cursor: pointer;

          span {
            margin-left: 2px;
            font-size: 14px;
            font-family: "PingFang SC";
            font-weight: 400;
            color: rgba(0, 0, 0, 0.65);
          }
        }
      }
    }
  }

  .content {
    width: 1184px;

    .middle {
      margin-top: 24px;

      .left {
        width: 793px;
        height: 492px;
        background: #ffffff;
        border-radius: 8px;
        opacity: 1;

        .selector {
          width: 707px;
          margin-top: 24px;
          margin-bottom: 12px;
          .ant-form-inline .ant-form-item {
            margin-right: 0px;
          }

          .class {
            font-size: 16px;
            font-family: PingFang SC-Semibold, PingFang SC;
            font-weight: 600;
            color: #009fe8;
          }
        }
      }

      .right {
        .title {
          font-size: 16px;
          font-family: PingFang SC-Semibold, PingFang SC;
          font-weight: 600;
          color: #262626;
        }

        .info {
          padding: 24px 24px 0 24px;
          box-sizing: border-box;
          width: 367px;
          height: 202px;
          background: #ffffff;
          border-radius: 8px;
          opacity: 1;

          .title {
            margin-bottom: 48px;
          }

          .item {
            font-size: 14px;
            font-family: PingFang SC-Regular, PingFang SC;
            font-weight: 400;
            color: rgba(0, 0, 0, 0.65);

            .data {
              margin-bottom: 16px;
              font-size: 14px;
              font-family: Helvetica-Bold, Helvetica;
              font-weight: bold;

              span {
                font-size: 24px;
              }
            }
          }
        }

        .operation {
          padding: 24px 23px 0 24px;
          box-sizing: border-box;
          width: 367px;
          height: 266px;
          margin-top: 24px;
          background: #ffffff;
          border-radius: 8px;

          .title {
            margin-bottom: 8px;
          }

          .btn {
            margin-top: 24px;
            margin-right: 16px;
            font-size: 14px;
            width: 96px;
            height: 40px;
            border-radius: 4px;
            border: none;
            outline: none;
            box-shadow: none;
            cursor: pointer;

            img {
              margin-right: 4px;
              vertical-align: middle;
            }
          }

          .btn:nth-child(3n) {
            margin-right: 0px;
          }

          .off {
            background: #f5222d;
            color: #ffffff;
          }

          .lock {
            background: #70bb48;
            color: #ffffff;
          }

          .reon {
            background: #ff9d4d;
            color: #ffffff;
          }

          .other {
            background: #f0f2f5;
            color: #565656;
          }

          .other:hover,
          .other:focus {
            background: @primary-color;
            color: #ffffff;
          }
        }
      }
    }

    .bottom {
      display: grid;
      margin-top: 24px;
      margin-bottom: 32px;
      grid-template-columns: repeat(5, 1fr);
      grid-template-rows: repeat(auto, 1fr);
      gap: 24px;

      .left {
        grid-column: span 2;
        grid-row: span 2;
        background: #ffffff;
        border-radius: 8px;

        .ant-menu-horizontal {
          line-height: 58px;
          padding-left: 24px;
        }

        .ant-menu {
          font-size: 16px;
          font-weight: 600;
          color: #262626;

          .ant-menu-item-selected {
            color: #262626;
          }
        }

        .info-list {
          padding: 8px 0px 53px 32px;

          .item {
            margin-top: 12px;
            font-size: 14px;
            font-family: PingFang SC-Regular, PingFang SC;
            font-weight: 400;
            color: rgba(0, 0, 0, 0.45);

            span {
              color: rgba(0, 0, 0, 0.85);
              margin-right: 16px;
            }
          }
        }
      }

      .box {
        width: 217px;
        height: 130px;
        background: #ffffff;
        border-radius: 8px;
        padding: 19px 20px 0 20px;
        position: relative;

        .status {
          height: 17px;
          font-size: 16px;
          font-family: Arial-Bold, Arial;
          font-weight: bold;
          color: #262626;
        }

        .device {
          margin-top: 12px;

          p {
            height: 20px;
            margin-top: 10px;
            margin-bottom: 0;
            font-size: 14px;
            font-family: PingFang SC-Regular, PingFang SC;
            font-weight: 400;
            color: rgba(0, 0, 0, 0.85);
          }
        }

        .command {
          margin-top: 15px;

          button {
            width: 67px;
            height: 26px;
            background: #ffffff;
            border-radius: 4px;
            border: 1px solid #d9dee5;
            outline: none;
            font-size: 12px;
            font-family: PingFang SC-Regular, PingFang SC;
            font-weight: 400;
            color: rgba(0, 0, 0, 0.65);
            cursor: pointer;
          }

          button:hover,
          button:focus {
            border: 1px solid @primary-color;
            background: @primary-color;
            color: #ffffff;
          }
        }

        .dust {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background: rgba(255, 255, 255, 0.85);
          border-radius: 8px;
          z-index: 99;
        }
      }
    }
  }
}

.content {
  position: relative;

  .content-count {
    position: absolute;
    right: 20px;
    bottom: -6px;
    color: rgba(0, 0, 0, 0.25);
    opacity: 0.85;
  }
}
</style>
