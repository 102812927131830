import Vue from "vue"
export function websocketSend(uuid, actionType, data, receiverIds) {
    let userId = Vue.ls.get("userInfo").id;
    let tenantId = Vue.ls.get("userInfo").tenantId;
    let schoolNum = Vue.ls.get("userInfo").schoolNum
    let obj = {
        tenantId,
        senderId: userId + "-" + uuid,
        schoolNum,
        actionType,
        clientType: "web",
        data,
        receiverIds
    };
    Object.keys(obj).forEach((key) => {
        if (obj[key] === null || obj[key] === undefined) {
            delete obj[key];
        }
    });
    return obj
}