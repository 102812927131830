import request from '@/utils/request'

//判断是否有网盘
export function isOwnDisk(params) {
    return request.get('/web/cloudDisk/isOwnDisk/', params)
}

//新增网盘
export function addOwnDisk(params) {
    return request.post('/web/cloudDisk/add/', params)
}

//获取文件目录
export function getCloudDiskList(params) {
    return request.get('/web/cloudDisk/list/', params)
}

//获取网盘使用情况
export function getCloudDiskUsed(params) {
    return request.get('/web/cloudDisk/checkUsed/', params)
}

//获取sts详情
export function getStsInfo(params) {
    return request.get('/web/oss/getStsInfo/', params)
}

//新建文件夹
export function addDir(params) {
    return request.get('/web/cloudDisk/mkdir/', params)
}

//删除文件或文件夹
export function deleteFile(params) {
    return request.remove('/web/cloudDisk/deleteFile/', params)
}

//批量删除文件或文件夹
export function deleteBatchFile(params) {
    return request.remove('/web/cloudDisk/deleteBatch/', params)
}

//获取文件下载地址
export function getDownloadUrl(params) {
    return request.get('/web/cloudDisk/getDownloadUrl/', params)
}

//移动到或者重命名
export function renameOrMoveFile(params) {
    return request.get('/web/cloudDisk/renameOrMoveFile/', params)
}

//获取所有文件夹
export function getDirStruct(params) {
    return request.get('/web/cloudDisk/getDirStruct/', params)
}
