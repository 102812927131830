export default {
    //建立连接
    open: 0,
    //发送心跳
    heart: 1,
    //查询设备列表信息
    queryTerminal: 2,
    //关机
    turnOff: 3,
    //重启
    restart: 4,
    //休眠/唤醒
    restOrBrightScreen: 5,
    //锁定/解锁
    lockOrUnlock: 6,
    //usb禁用/启用
    usb: 7,
    //消息推送
    newsSend: 8,
    //文件推送
    fileSend: 9,
    //消息推送重发
    newsSendAgain: 10,
    //文件下发重发
    fileSendAgain: 11,
    //断开连接
    close: 12,
    //安装
    install: 13,
    //卸载
    uninstall: 14,
    //重新安装
    installAgain: 15,
    //重新卸载
    uninstallAgain: 16,
    //查询安装列表
    queryInstall: 17,
    //查询卸载列表
    queryUninstall: 18,
    //定时开机
    scheduleTurnOn: 35,
    //定时关机
    scheduleTurnOff: 19,
    //定时重启
    scheduleRestart: 20,
    //定时消息推送
    scheduleNewsSend: 21,
    //定时消息文件推送
    scheduleFileSend: 22,
    //查询系统cpu信息等
    querySystemInfo: 23,
    //查询设备和安卓信息
    queryDeviceAndAndroidInfo: 24,
    //查询客户端ip地址
    queryDeviceIp: 27,
    //开始推流
    startStreaming: 28,
    //结束推流
    stopStreaming: 29,
    //数据分析的数量统计
    dataAnalysisNum: 30,
    //数据概览的数量统计
    dataOverviewNum: 31,
    //数据概览地图标点
    dataOverviewMapFlag: 32,
    //恢复出厂设置
    restoreSetting: 33,
    //还原电脑
    restorePc: 34,
    //查询教室设备列表信息
    queryTerminalClassroom: 50,
    //开机
    turnOn: 51,
    //单个设备各个软件内存占用情况
    queryMemoryPercentage: 37,
    //单个设备开机时间列表
    queryTurnOnCount: 38,
    //异常分析设备自检
    deviceSelfCheck: 97,
    //打铃
    ring: 41,
    //定时打铃
    scheduleRing: 39,
}