import request from '@/utils/request'

//获取参数列表
export function getConfigList(params) {
    return request.get('/api-system/sys-config', params)
}

//新增或修改参数
export function configAddOrUpdate(params) {
    return request.post('/api-system/sys-config/saveOrUpdate', params)
}

//获取参数详情
export function getConfig(id) {
    return request.get('/api-system/sys-config/' + id)
}

//删除参数
export function configRemove(params) {
    return request.remove('/api-system/sys-config/deleteBatch', params)
}

//刷新缓存
export function refreshCache(params) {
    return request.remove('/api-system/sys-config/refreshCache', params)
}

//根据参数键名获取参数信息
export function getConfigByConfigKey(params) {
    return request.get('/api-system/sys-config/select-by-key',params)
}