import request from '@/utils/request'
import requestLogin from '@/utils/request-login'

//获取教室终端列表
export function getTerminalList(params) {
    return request.get('/api-device/device/device-sn-list', params)
}

//获取所有设备列表
export function getDeviceList(params) {
    return request.get('/api-device/device/device-list', params)
}

//新增定时任务
export function scheduleJobAdd(params) {
    return request.post('/api-device/device/schedule-job/insert', params)
}

//获取视频流的token
export function getStreamToken(params) {
    return request.get('/api-device/stream/auth-token', params)
}

//获取教室列表
export function getTerminalClassroomList(params) {
    return request.get('/api-device/device/device-class-List', params)
}